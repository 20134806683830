.label input {
  position: absolute;
  left: -9999px;
  pointer-events: none;
}

.label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
  --radio-color: var(--platform-secondary-700);
}

.label span {
  display: block;
  font-size: 16px;
  text-transform: capitalize;
}

.label {
  cursor: pointer;
}

.label:has(span[color='success']) {
  --radio-color: var(--camsol-status-success);
}

.label:has(span[color='success']) span {
  color: var(--camsol-status-success);
}

.label:has(span[color='error']) {
  --radio-color: var(--camsol-status-error);
}

.label:has(span[color='error']) span {
  color: var(--camsol-status-error);
}

.circle {
  aspect-ratio: 1/1;
  width: 2rem;
  border-radius: 100%;
  border: 2px solid var(--radio-color);
  background-color: transparent;
  position: relative;
  pointer-events: none;
}

.dot {
  aspect-ratio: 1/1;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--radio-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: left;
}

.show {
  transform: scale(1) translate(-50%, -50%);
}

.hide {
  transform: scale(0) translate(-50%, -50%);
}
