.top__section {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  /* flex-wrap: wrap; */
}

.navigator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 323px;
  column-gap: 7rem;
}

.previous {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chevron__left {
  margin-bottom: -0.5rem;
}

.right__chevron {
  transform: rotate(180deg);
  margin-top: -0.5rem;
}

.next {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.previous__p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #5c187a;
}

.next__p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #5c187a;
}

@media screen and (max-width: 500px) {
  .previous,
  .next {
    border: 1px solid #5c187a;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .navigator {
    column-gap: 0rem;
  }

  .top__section {
    gap: 1.5rem;
  }

  .previous {
    margin-right: 8px;
  }

  .next__p,
  .previous__p {
    font-size: 12px;
  }
}
