.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin-bottom: 3.5rem;
  /* height: 254px; */
  padding-top: 9.7rem;
}

.container h3 {
  margin-bottom: 1.6rem;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: 0.08em;
  color: #1c1c1c;
}

.container p {
  width: 360px;
  height: 44px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #545454;
}

.container .email {
  margin-bottom: 2.7rem;
}
.resend {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #a9a9a9;
  justify-content: center;
}

.resend h4 {
  text-decoration: none;
  color: #5c187a;
  cursor: pointer;
  margin-left: 0.5rem;
}

@media screen and (max-width: 900px) {
  .container {
    padding-top: 0;
  }

  .container .email {
    margin-bottom: 2.4rem;
  }
}
