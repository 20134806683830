.content {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  gap: 12px;
  margin-top: 2.4rem;
}

p:empty {
  display: none;
}

.content iframe {
  min-height: 700px;
  resize: vertical;
}

.content ul {
  padding-left: 2rem;
}

.content a {
  text-decoration: underline;
  color: inherit;
}

.content h1,
.content h2,
.content h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #00090d;
}

.content iframe,
.content video {
  width: 100%;
}

.content h2 {
  font-size: 2rem;
}

.content h3 {
  font-size: 1.8rem;
}

.content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #545454;
  word-break: break-all;
}

.content img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.content ul,
.content ol {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: flex-start;
  flex-direction: column;
  -ms-flex-direction: column;
  gap: 0.4rem;
}

.content ul li::before {
  content: '';
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.content ul li,
.content ol li {
  color: #545454;
  font-weight: 400;
  font-size: 1.6rem;
}

.audio__wrapper {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 1rem 2rem;
  gap: 22px;
  width: 100%;
  height: 72px;
  background: #f4f4f4;
  border-radius: 16px;
}

.audio__wrapper button {
  color: var(--platform-secondary-900);
  border: none;
  background: none;
}

.audio__wrapper p {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.8rem;
  color: var(--camsol-black);
}

.audio__wrapper .progress {
  width: 75%;
}

.parent__container {
  width: 100%;
  margin-right: 1.6rem;
  /* outline: 1px solid blue;   */
}

.container {
  height: 10px;
  width: 100%;
  background-color: var(--platform-secondary-50);
  border-radius: 8px;
  margin: 50;
}

.filler__styles {
  height: 100%;
  border-radius: inherit;
  text-align: right;
  transition: width 150ms linear;
}

.label {
  padding: 5;
  color: transparent;
}

.content pre {
  background: var(--platform-secondary-900) !important;
  border: 2px solid var(--platform-secondary-700);
  border-radius: 16px !important;
  padding: 2rem;
}

.content pre code {
  background: none !important;
  color: var(--camsol-white);
  font-size: 1.5rem;
}

.content pre code > span > span {
  color: var(--camsol-white);
}

.codeBlock {
  position: relative;
}
.codeBlock pre {
  background: var(--platform-secondary-900) !important;
  border: 2px solid var(--platform-secondary-700);
  border-radius: 16px !important;
}

.codeBlock code {
  background: none !important;
}

.codeBlock code > span > span {
  color: var(--camsol-white);
}

.codeBlock code span.token {
  color: var(--camsol-white) !important;
}

.copy {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 1px solid var(--camsol-white);
  border-radius: 100%;
  aspect-ratio: 1/1;
  width: 35px;
  color: var(--camsol-white);
  display: grid;
  place-content: center;
  transition: background-color 300ms ease;
}

.copy:hover {
  background-color: var(--platform-secondary-400);
}

@media screen and (max-width: 650px) {
  .audio__wrapper p {
    font-size: 10px;
    line-height: 15px;
  }

  .audio__wrapper .progress {
    width: 65%;
  }

  .content p,
  .content li {
    text-align: justify;
  }
}
