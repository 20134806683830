.profile {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  padding: 4rem 3.2rem;
}

.sidebar {
  width: 320px;
  min-height: 605px;
  margin-right: 3.2rem;
  padding: 32px 16px;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 12rem;
}

.modules__tabs {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  width: 100%;
  padding-top: 12rem;
}

.switch__tabs {
  margin-bottom: 2.4rem;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 6px 8px;
  gap: 12px;
  min-height: 58px;
  background: #f4f4f4;
  border-radius: 12px;
  width: fit-content;
  overflow-x: auto;
}

.switch__tabs .tab a {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 22px;
  color: #383838;
  text-decoration: none;
  padding: 12px 24px;
  height: 46px;
  white-space: nowrap;
  background: transparent;
  box-shadow: 0px 4px 6px -1px transparent, 0px 2px 4px -1px transparent;
  -webkit-box-shadow: 0px 4px 6px -1px transparent, 0px 2px 4px -1px transparent;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.switch__tabs .tab.active a {
  background: #fff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .switch__tabs {
    width: 100%;
    overflow-x: auto;
  }
}
