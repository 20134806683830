.parent__container {
  width: 100%;
  margin-right: 1.6rem;
  /* outline: 1px solid blue;   */
}

.more {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px;
  margin: 1.6rem 0;
  width: 320px;
  gap: 1.6rem;
}

.course__heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.trophy {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 9px;
  /* padding-left: 20px; */
  gap: 8px;
}

.percentage__completed {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  padding-left: 20px;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
}

.trophy p {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 150%;
  white-space: nowrap;
  margin-right: 2.5rem;
}

.percentage {
  color: var(--platform-secondary-700);
  font-weight: 700;
  font-size: 1.2rem;
}

.container {
  height: 10px;
  width: 100%;
  background-color: var(--platform-secondary-50);
  border-radius: 8px;
}

.filler__styles {
  height: 100%;
  border-radius: inherit;
  text-align: right;
}

.label {
  color: transparent;
}

@media screen and (max-width: 500px) {
  .more {
    width: 100%;
  }
}
