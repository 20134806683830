.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
}

.image {
  width: 100%;
  aspect-ratio: 8/10;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.info {
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-top: 16px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  color: var(--camsol-neutral-900);
}

.imageThumbnail {
  width: 20px;
  height: 20px;
  border-radius: 64px;
}

.authorInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 19px;
  font-size: 12px;
  color: var(--camsol-neutral-700);
  font-weight: 400;
}

.authorInfo > span {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
}
