.full__course {
  padding: 3rem 3.2rem;
  padding-top: 15rem;
}

.toggler__wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigatortoggler {
  display: none;
  gap: 16px;
  align-items: center;
  cursor: pointer;
}

.navigatortoggler h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #00090d;
}

.close__icon {
  cursor: pointer;
}

.courses__section {
  display: flex;
  display: -ms-flexbox;
  gap: 32px;
}

.course__details {
  width: 100%;
}

.more__display {
  width: 323px;
  z-index: 9;
}

.more__display.static {
  position: sticky;
  position: -webkit-sticky;
  top: 160px;
}

@media screen and (max-width: 500px) {
  .full__course {
    padding: 3rem 1.6rem;
    padding-top: 15rem;
  }
  .navigatortoggler {
    display: flex;
  }
}
