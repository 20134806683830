.card {
  position: relative;
  width: 195px;
  background-color: #fff;
  padding: 24px 16px;
  z-index: 9999;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.imageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
}

.welcome {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.detail {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 16px;
  cursor: pointer;
}

.detail .icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail .text {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.linkDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;
}
.logoutButton {
  background-color: var(--platform-secondary-700);
  margin-top: 16px;
  padding: 10px 16px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 105px;
}

.line {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(175, 175, 175, 0),
    rgb(175, 175, 175),
    rgba(197, 197, 197, 0)
  );
}

.buttonDiv {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .logoutButton {
    width: 120px;
    padding: 12px 16px;
  }
}
