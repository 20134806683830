.body {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  overflow: hidden;
  padding-bottom: 1.6rem;
  flex-shrink: 0;
  --content-padding: 1.6rem;
}

.body > a > img {
  aspect-ratio: 3/2;
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

.body h2 {
  max-height: 40px;
  min-height: 40px;
  line-height: 39px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 800;
  font-size: 3.2rem;
  padding-inline: var(--content-padding);
  /* outline: 1px solid blue; */
}

.instructorDetails {
  padding-inline: var(--content-padding);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
}

.instructorDetails span {
  font-weight: 600;
}

.courseDetails {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding-inline: var(--content-padding);
}

.detail {
  display: flex;
  gap: 0.5rem;
  font-weight: 400;
  line-height: 1rem;
  font-size: 1.4rem;
  align-items: center;
  color: var(--camsol-neutral-500);
}

.detail span {
  color: var(--platform-secondary-700);
  /* width: 24px;
 height: 24px; */
}

.detail span:nth-child(2) {
  display: none;
}

.lang span:nth-child(2) {
  display: inline !important;
  color: var(--camsol-neutral-500);
}

.avatar {
  aspect-ratio: 1/1;
  width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.border {
  width: 2rem !important;
  border: 1px solid var(--platform-secondary-700);
}

.border:not(:first-child) {
  margin-left: -0.5rem;
}

.count {
  font-size: 1.2rem;
  color: var(--camsol-neutral-500);
}

@media screen and (max-width: 768px) {
  .body {
    width: 100%;
    gap: 0.8rem;
    padding-bottom: 0.8rem;
    --content-padding: 0.8rem;
  }

  .body > a > img {
    aspect-ratio: 9/10;
  }

  .instructorDetails {
    font-size: 10px;
    gap: 4px;
    align-items: flex-start;
  }

  .avatar {
    width: 18px;
    object-fit: cover;
  }

  .studentAvatars,
  .count,
  .lang {
    display: none;
  }

  .detail {
    font-size: 8px;
  }

  .detail span:first-child {
    display: none;
  }

  .detail span:nth-child(2) {
    display: inline;
  }

  .body h2 {
    font-weight: 600;
    font-size: 14px;
    min-height: 50px;
    padding-inline: var(--content-padding);
    line-height: 16.94px;
    max-height: 18px;
    min-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    /* outline: 1px solid blue; */
  }
}
