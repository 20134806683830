.checkbox__wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.checkbox__wrapper input {
  accent-color: var(--platform-secondary-700);
}

.success input {
  accent-color: var(--camsol-status-success);
}

.error input {
  accent-color: var(--camsol-status-error);
}

.label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
  cursor: pointer;
}

.label span {
  display: block;
  font-size: 16px;
  text-transform: capitalize;
}

.success span {
  color: var(--camsol-status-success);
}

.error span {
  color: var(--camsol-status-error);
}

.label input {
  width: 2rem;
  height: 2rem;
}

span:empty {
  display: none;
}

.remark {
  font-size: 14px;
}
