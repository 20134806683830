.parent__div {
  display: flex;
  width: 100%;
}

.input__div {
  width: 50%;
}

.img__container {
  width: 50%;
  background-color: var(--platform-secondary-700);
  min-height: 100vh;
  padding: 2rem 4.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_styles {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 466px;
  height: 456px;
  padding: 0 7rem;
}

.box {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(75px);
  border-radius: 1.6rem;
  align-items: flex-start;
  padding: 3rem;
  margin-top: 2.4rem;
  width: 100%;
  min-height: 299px;
}

.heading {
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.8rem;
  letter-spacing: 0.08em;
  color: #ffffff;
  padding-top: 3.4rem 0;
  min-height: 144px;
}

.paragraph {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: justify;
  color: #ffffff;
  padding-top: 2.4rem;
}

.dots {
  display: flex;
  gap: 0.8rem;
  margin-top: 2.4rem;
  width: 100%;
}

.dot {
  width: 16px;
  height: 4px;
  background-color: #ffffff;
  transition: 0.7s ease-in-out;
  border-radius: 8px;
  opacity: 0.4;
  cursor: pointer;
}

.btn {
  font-weight: 500;
}

.btn:hover {
  background-color: var(--platform-secondary-600) !important;
}

.btn2:hover {
  color: var(--platform-secondary-600) !important;
  border: 2px solid var(--platform-secondary-600) !important;
  background: none !important;
}

.dot.active {
  width: 48px;
  opacity: 1;
}

.logo {
  padding: 4rem 3.7rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 17.8rem;
}

.title {
  width: 123px;
  height: 48px;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  align-items: center;
  letter-spacing: 0.08em;
  color: #1c1c1c;
  margin-bottom: 2rem;
  display: flex;
}

.signup__title {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  align-items: center;
  letter-spacing: 0.08em;
  color: #1c1c1c;
  margin-bottom: 2rem;
  display: flex;
}

.p__styles {
  width: 360px;
  height: 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
  align-items: center;
  margin-bottom: 2.7rem;
  display: flex;
}

.field__title {
  /* width: 43px; */
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  color: #000000;
}

.instruction {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a9a9a9;
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;
}

.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
}

.checkbox__container p a {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #5c187a;
}

.checkbox p {
  padding-left: 0.8rem;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #545454;
}

.form__control h4 {
  font-weight: 400;
  font-size: 14px;
  color: #a9a9a9;
}

.input__wrapper {
  margin-bottom: 2.4rem;
  position: relative;
  /* width: 360px; */
}

.input__wrapper:focus-within .suggestion {
  transform: scale(1);
}

.suggestion {
  position: absolute;
  top: 5.1rem;
  z-index: 20;
  /* outline: 1px solid blue; */
  border: 1px solid var(--camsol-neutral-200);
  background-color: var(--camsol-white);
  box-shadow: var(--shadow-lg);
  border-radius: 4px;
  min-width: fit-content;
  padding-top: 5px;
  transition: transform 300ms ease;
  transform: scale(0);
  transform-origin: top;
}

.hidesuggestion {
  transform: scale(0) !important;
}

.suggestion > * {
  padding-inline: 0.5rem;
}

.suggestion span {
  font-size: 8px;
  font-style: italic;
  color: var(--camsol-neutral-500);
  white-space: nowrap;
}

.suggestion p {
  width: 100%;
  white-space: nowrap;
  padding: 5px 0.5rem;
  cursor: pointer;
  transition: background-color 150ms ease;
  font-size: 1.1rem;
}

.suggestion p:hover {
  background-color: var(--camsol-neutral-200);
}

.submit__btn {
  margin-bottom: 2.4rem;
}

.link a {
  text-decoration: none;
  color: #5c187a;
  font-weight: 600;
  font-size: 14px;
}

.validate__error {
  color: rgb(247, 50, 50);
  font-style: italic;
  font-weight: 500;
  margin-top: 0.3rem;
}

p:empty {
  display: none;
}

.list {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  /* gap: 1.2rem; */
  margin-top: 1.6rem;
  position: relative;
  /* margin-left: 2.5rem; */
}

.list li {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 1440px) {
  .img_styles {
    width: 400px;
    height: 330px;
  }
}

@media screen and (max-width: 1153px) {
  .form__control h4 {
    width: 250px;
  }
  .box {
    min-height: 430px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .img__container {
    padding: 2rem 2.8rem;
  }
  .img_styles {
    width: 100%;
    height: unset;
    overflow: visible;
    padding-inline: 0px;
    display: flex;
    justify-content: center;
  }
  .img_styles img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    margin-right: -75px;
  }
}

@media screen and (max-height: 950px) {
  .heading {
    font-size: 4rem;
    line-height: 4.5rem;
  }
  /* .form__control {
    width: 100%;
  } */

  .img_styles img {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 900px) {
  .img__container {
    display: none;
  }
  .input__div {
    width: 1000%;
  }
  .logo {
    padding-top: 4.8rem;
    padding-bottom: 1.6rem;
    padding-inline: 2.2rem;
    /* display: flex;
    justify-content: center; */
  }
  .content {
    padding: 0 1.6rem;
    padding-bottom: 3rem;
    margin-top: auto;
    margin-bottom: auto;
    min-height: calc(100vh - 150px);
  }
  .signup__title {
    padding-top: 2.4rem;
  }
}
