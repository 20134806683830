.body {
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  padding: var(--py) var(--px);
  padding-bottom: 7rem;
  position: relative;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
}

.body::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, #0f0513, #0f05131f);
  transform: rotateY(180deg);
  opacity: 0.7;
  z-index: -1;
}

.body > h1 {
  font-weight: 700;
  font-size: 3.5rem;
  width: 60%;
  text-align: center;
  color: var(--camsol-neutral-100);
}

.body > p {
  font-size: 1.5rem;
  color: var(--platform-secondary-50);
}

.details,
.details > div,
.details span {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.details span {
  gap: 0.5rem;
  font-size: 1.3rem;
  color: var(--camsol-white);
  font-weight: 500;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.custom {
  --bg: transparent;
  padding: 1rem 2rem;
}

.custom:hover {
  background-color: transparent;
  color: var(--platform-secondary-700);
  border: 2px solid var(--platform-secondary-700);
}

.custom:active {
  --bg: var(--technology-secondary-200);
}

.btn {
  background: transparent;
  border: 2px solid #e2e2e2;
  color: #e2e2e2;
}

.btn:hover {
  border: 2px solid var(--platform-secondary-600) !important;
  color: var(--platform-secondary-600) !important;
  background: transparent !important;
}

@media screen and (max-width: 900px) {
  .body {
    min-height: 50vh;
  }

  .body > h1 {
    font-size: 4rem;
    width: 100%;
  }

  .details {
    flex-direction: column-reverse;
  }
}
