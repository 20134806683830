.wrapper {
  width: 100%;
  padding: 6.4rem var(--px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.faq__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  max-width: calc(100vw - var(--px));
  width: 100%;
}

.heading__styles {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 6.4rem;
  text-align: center;
  margin-bottom: 48px;
}

.accordion__item__btn {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  border: 0;
  padding: 15px 0;
  font-size: 1.6rem;
  cursor: pointer;
  user-select: none;
  font-family: var(--inter-font);
  font-size: 20px;
  font-weight: 400;
}

.accordion__item__btn:hover {
  background: none !important;
}

.bullet {
  width: 6px;
  height: 6px;
  background-color: #000000;
  border-radius: 50%;
}

.title__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  padding: 15px 0;
  padding-bottom: 1.5rem;
  font-size: 16px;
  font-family: var(--inter-font);
  font-weight: 300;
  line-height: 150%;
  color: #545454;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.customAccordion {
  padding: 16px 46px;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}

/* Newsletter section */
.pattern_div {
  display: flex;
  position: relative;
  width: 100%;
}

.pattern_div .pattern_span {
  position: absolute;
  right: -3.5%;
  top: -40px;
  z-index: -5;
  display: block;
}

.pattern_div .container {
  background: var(--platform-secondary-900);
  gap: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 64px 48px;
  width: 100%;
  border-radius: 32px;
  backdrop-filter: blur(80px);
  box-shadow: 0px 2px 8px rgba(142, 89, 147, 0.08), 0px 20px 32px rgba(88, 61, 97, 0.16);
}

.pattern_div .newsletter_span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 1;
  gap: 12px;
  width: 50%;
}

.pattern_div .container .newsletter_sub_span > * {
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #ffffff;
  flex: none;
  align-self: stretch;
}

.pattern_div .newsletter_span .newsletter {
  color: var(--platform-secondary-200);
}

.pattern_div .newsletter_span .extra_info {
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--camsol-neutral-200);
}

.pattern_div .input_container {
  position: relative;
  display: flex;
  gap: 25px;
  margin-top: 1rem;
  align-items: center;
  width: 50%;
}

.pattern_div .input_container .input {
  box-sizing: border-box;
  padding: 12px 14px 12px 24px;
  gap: 16px;
  width: 100%;
  height: 88px;
  background: #ffffff;
  border: 1px solid #e3d4f3;
  box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
  border-radius: 16px;
  font-size: 18px;
  font-weight: 300;
}

.pattern_div .btn_span {
  position: absolute;
  right: 14px;
  z-index: 2;
  border: none;
  top: 50%;
  color: white;
  transform: translateY(-50%);
  border-radius: 16px;
  box-shadow: 0px 4px 100px -28px rgba(0, 0, 0, 0.25);
}

.pattern_div .input_btn {
  line-height: 17px;
  border-radius: 16px;
  padding: 20px 30px !important;
  font-weight: 500;
  height: fit-content;
  background-color: var(--platform-secondary-900);
}

.pattern_div .input_btn:hover {
  background-color: var(--platform-secondary-800) !important;
}

@media only screen and (max-width: 1180px) {
  .pattern_div .container {
    padding: 50px 48px;
    gap: 0px;
  }
  .pattern_div .newsletter_span {
    gap: 10px;
    width: 50%;
  }

  .pattern_div .input_container {
    width: 50%;
  }

  .pattern_div .input_container .input {
    padding: 10px 11px 10px 20px;
    font-size: 16px;
  }

  .pattern_div .btn_span {
    right: 7px;
  }
  .pattern_div .input_btn {
    padding: 16px 20.5px !important;
    font-size: 18px;
  }

  .pattern_div .newsletter_span .extra_info {
    font-size: 18px;
    line-height: 21.5px;
  }
}

@media screen and (max-width: 1050px) {
  .pattern_div .container {
    padding: 44px 48px;
  }
  .pattern_div .newsletter_span {
    width: 300px;
  }

  .pattern_div .input_container {
    width: 60%;
  }

  .pattern_div .input_btn {
    padding: 16px 15.5px !important;
  }
  .heading__styles {
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  .heading__styles {
    font-size: 3rem;
    margin-bottom: 16px;
  }
  .customAccordion {
    padding-inline: 16px;
  }
  .accordion__item__btn {
    padding: 0 0;
  }
  .title__wrapper {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }

  /* Newsletter section */
  .wrapper {
    padding: 24px 6vw;
  }

  .pattern_div {
    width: 100%;
  }
  .pattern_div .container {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    padding: 24px 16px;
    border-radius: 16px;
  }

  .pattern_div .container .newsletter_span {
    width: 100%;
    align-items: flex-start;
  }

  .pattern_div .container .newsletter_span .newsletter_sub_span > * {
    font-size: 20px;
    line-height: 24px;
  }

  .pattern_div .container .input_container {
    width: 100%;
  }

  .pattern_div .container .input_container .input {
    height: 48px;
    padding: 12px 0px 12px 24px;
    border-radius: 8px;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
  }

  .pattern_div .container .input_container .btn_span .input_btn {
    border-radius: 12px;
    padding: 10px 17px !important;
    font-size: 14px;
  }

  .pattern_div .pattern_span,
  .pattern_div .container .newsletter_span .extra_info {
    display: none;
  }
}
