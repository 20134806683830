/** GLOBAL FONT FAMILY *******************************************************/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-family: var(--inter-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-weight: normal;
  /* outline: 1px solid red; */
}

:root {
  --inter-font: 'Inter', sans-serif;
  --px: 12.8rem;
  --gutter: 3.2rem;

  --camsol-primary-900: #00151e;
  --camsol-primary-800: #001b27;
  --camsol-primary-700: #002231;
  --camsol-primary-600: #002e42;
  --camsol-primary-500: #003952;
  --camsol-primary-400: #336175;
  --camsol-primary-300: #678998;
  --camsol-primary-200: #a3b8c1;
  --camsol-primary-100: #d7e0e4;
  --camsol-primary-50: #ebeff1;

  --camsol-primary-gradient-900: linear-gradient(
    to top,
    var(--camsol-primary-900),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-800: linear-gradient(
    to top,
    var(--camsol-primary-800),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-700: linear-gradient(
    to top,
    var(--camsol-primary-700),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-600: linear-gradient(
    to top,
    var(--camsol-primary-600),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-500: linear-gradient(
    to top,
    var(--camsol-primary-500),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-400: linear-gradient(
    to top,
    var(--camsol-primary-400),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-300: linear-gradient(
    to top,
    var(--camsol-primary-300),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-200: linear-gradient(
    to top,
    var(--camsol-primary-200),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-100: linear-gradient(
    to top,
    var(--camsol-primary-100),
    var(--camsol-primary-900)
  );
  --camsol-primary-gradient-50: linear-gradient(
    to top,
    var(--camsol-primary-50),
    var(--camsol-primary-900)
  );

  --technology-secondary-900: #003952;
  --technology-secondary-800: #004c6d;
  --technology-secondary-700: #005f87;
  --technology-secondary-600: #007fb6;
  --technology-secondary-500: #009fe3;
  --technology-secondary-400: #33b2e9;
  --technology-secondary-300: #67c6ee;
  --technology-secondary-200: #a3dcf5;
  --technology-secondary-100: #ebf7fd;
  --technology-secondary-50: #f2f7fe;

  --platform-secondary-900: #380e4a;
  --platform-secondary-800: #3b2a4d;
  --platform-secondary-700: #5c187a;
  --platform-secondary-600: #7c20a3;
  --platform-secondary-500: #9b28cc;
  --platform-secondary-400: #9579b4;
  --platform-secondary-300: #b09bc7;
  --platform-secondary-200: #dbb1ed;
  --platform-secondary-100: #eae5f0;
  --platform-secondary-50: #eae5f0;

  --innovation-secondary-900: #01322f;
  --innovation-secondary-800: #02423e;
  --innovation-secondary-700: #02524d;
  --innovation-secondary-600: #036e67;
  --innovation-secondary-500: #048a81;
  --innovation-secondary-400: #36a19a;
  --innovation-secondary-300: #69b9b4;
  --innovation-secondary-200: #a4d5d2;
  --innovation-secondary-100: #d8edeb;
  --innovation-secondary-50: #ebf6f5;

  --foundation-secondary-900: #5b310c;
  --foundation-secondary-800: #794110;
  --foundation-secondary-700: #975014;
  --foundation-secondary-600: #ca6c1a;
  --foundation-secondary-500: #fd8721;
  --foundation-secondary-400: #fd9f4d;
  --foundation-secondary-300: #feb77b;
  --foundation-secondary-200: #fed4af;
  --foundation-secondary-100: #ffecdc;
  --foundation-secondary-50: #fff6ee;

  --camsol-neutral-900: #000e14;
  --camsol-neutral-800: #222222;
  --camsol-neutral-700: #383838;
  --camsol-neutral-600: #545454;
  --camsol-neutral-500: #7a777d;
  --camsol-neutral-400: #8d8d8d;
  --camsol-neutral-300: #a9a9a9;
  --camsol-neutral-200: #c5c5c5;
  --camsol-neutral-100: #f4f4f4;
  --camsol-neutral-50: #ebf6f5;

  --camsol-black: #000000;
  --camsol-white: #ffffff;

  --camsol-status-success: #49b646;
  --camsol-status-error: #ff4e4e;

  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.05);
  --shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  --shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  width: 100%;
  overflow-x: hidden;
}

::selection {
  background-color: var(--camsol-black);
  color: var(--camsol-white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

a {
  text-decoration: none;
}

#rotate {
  animation: rotate 3s linear infinite;
  -webkit-animation: rotate 3s linear infinite;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@media screen and (max-width: 1440px) {
  :root {
    --px: 11.2rem;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --px: 6.4rem;
  }
}

@media screen and (max-width: 900px) {
  :root {
    --px: 3.2rem;
    --gutter: 1.6rem;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --px: 1.6rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
