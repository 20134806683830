.content__wrapper {
  display: flex;
  flex-direction: row;
  padding: 8px 15px;
  border-radius: 8px;
  transition: background-color 300ms ease;
  cursor: pointer;
  gap: 0.8rem;
}

.disabled {
  cursor: not-allowed;
}

.disabled h3,
.disabled p {
  color: #575757 !important;
}

.disabled .play {
  color: #575757 !important;
}

.current h3,
.current p {
  color: var(--camsol-black) !important;
}

.current .play {
  color: var(--platform-secondary-700) !important;
}

.current {
  background-color: transparent;
  cursor: pointer;
}

.content__wrapper:hover,
.current:hover {
  background-color: var(--camsol-neutral-50);
}

.active {
  background-color: #5c187a !important;
  cursor: pointer;
}

.active h3,
.active p {
  color: #fff !important;
}

.content__wrapper .play {
  color: var(--platform-secondary-700);
}

.content__wrapper.active .play {
  color: #fff !important;
}

.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.duration {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #717171;
  margin-top: 0.8rem;
}
