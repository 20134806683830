.body {
  outline: 3px dotted var(--platform-secondary-700);
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.questions,
.questions ul {
  padding-left: 1rem;
}

.questions ul {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.question {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.btn {
  padding: 0.9rem 1.2rem;
  font-size: 1.5rem;
  height: fit-content;
  margin-top: 1.5rem;
  margin-left: auto;
}
