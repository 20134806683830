.checkbox__wrapper input {
  accent-color: var(--platform-secondary-700);
}

.label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
  cursor: pointer;
}

.label span {
  display: block;
  font-size: 16px;
  text-transform: capitalize;
}

.label input {
  width: 2rem;
  height: 2rem;
}
