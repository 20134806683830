.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--px);
  padding-bottom: 24px;
  gap: 50px;
  letter-spacing: 0.02em;
}

.wrapper {
  width: 100%;
  padding: 6.4rem 0;
  padding-bottom: 0;
  padding: 20px 0;
}

.bottom_div {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 20px 0;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.logo_div {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 276px;
}

.logo_div .logo_div_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--camsol-neutral-800);
}

.logo_div img {
  width: 150px;
}

.sub_container .main {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  font-style: normal;
  display: flex;
  align-items: center;
  color: var(--camsol-neutral-800);
}

.sub_container_div {
  display: none;
  justify-content: space-around;
  gap: 4rem;
  width: 47%;
  flex: 1;
  padding-inline: 5px;
}

.sub_container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.sub_container a {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--camsol-neutral-800);
  margin-bottom: 2px;
}

.copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  margin-bottom: 24px;
}

.inputBtn {
  font-weight: 500;
}

.inputBtn:hover {
  background-color: var(--platform-secondary-600);
}

.newsletter_div {
  gap: 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
}

.newsletter_div .main {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--camsol-neutral-800);
}

.newsletter_div .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--camsol-neutral-700);
}

.newsletter_div .inputDiv {
  display: flex;
  gap: 8px;
}

.newsletter_div .input {
  width: 100%;
  border-radius: 8px;
  border: 1.5px solid var(--camsol-neutral-200);
  padding: 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.newsletter_div .input:focus {
  outline: none;
}

.newsletter_div .button {
  background-color: var(--platform-secondary-700);
  padding: 16px 24px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 6px;
}

@media screen and (max-width: 1366px) {
  .sub_container_div {
    min-width: 40%;
    flex: 1;
    gap: 1rem;
  }
  .logo_div {
    gap: 20.5px;
    width: 170px;
  }
}

@media screen and (max-width: 1180px) {
  .sub_container_div .sub_container {
    gap: 13px;
  }
  .sub_container_div .sub_container .main,
  .newsletter_div .main {
    font-size: 16.4px;
    line-height: 19.7px;
  }

  .sub_container_div .sub_container a,
  .logo_div .logo_div_text,
  .newsletter_div .text {
    font-size: 11.5px;
  }

  .newsletter_div {
    gap: 13.1px;
    width: 229.5px;
  }

  .logo_div {
    gap: 20.5px;
    width: 170px;
  }

  .newsletter_div .input {
    padding: 13.1px;
    font-size: 10.7px;
    line-height: 13.1px;
  }

  .newsletter_div .button {
    padding: 13.1px 19.7px;
    font-size: 13.1px;
    line-height: 15.6px;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    gap: 0px;
    padding: 24px 32px;
  }
  .logo_div {
    gap: 16px;
    width: 100%;
  }

  .logo_div .logo_div_text {
    font-size: 12px;
    line-height: 15px;
    color: var(--camsol-neutral-700);
  }

  .sub_container_div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .sub_container_div .sub_container {
    width: 50%;
    margin-top: 24px;
  }

  .sub_container_div .sub_container .main {
    line-height: 24px;
  }

  .sub_container_div .sub_container a {
    margin-bottom: 0px;
  }

  .newsletter_div {
    display: none;
  }

  .copyright {
    margin-top: 4px;
    margin-bottom: 0px;
  }
}
